<template>
  <div id="app" :class="appClass">
    <Sidebar id="sidebar" :class="{ 'expanded': isSidebarExpanded }"/>
    <div id="sidebarFilter" v-if="isSidebarExpanded" @click="onCollapseSidebar()"></div>
    <router-view />
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: 'App',
  components: { Sidebar },
  computed: {
    isSidebarExpanded: function () {
      return this.$store.state.isSidebarExpanded;
    },
    appClass: function () {
      var classes = {};
      var route = this.$route;
      if (route.meta && route.meta.scrollMode == "custom")
        classes["fill"] = true;
      return classes;
    }
  },
  methods: {
    onExpandSidebar: function () {
      this.$store.commit("expandSidebar");
    },
    onCollapseSidebar: function () {
      this.$store.commit("collapseSidebar");
    }
  },
  mounted: function () {
    this.$setViewport(375);
  }
}
</script>
<style lang="less" scoped>
  .fill {
    width: 100%;
    height: 100%;
  }

  #sidebar {
    position: fixed;
    left: -240px;
    top: 0;
    width: 240px;
    z-index: 101;
    transition: left 0.5s;
  }
  #sidebar.expanded {
    left: 0;
    box-shadow: 0px 0px 8px #d0d0d0;
  }

  #sidebarFilter {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.05;
    z-index: 100;
  }
</style>
