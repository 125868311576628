// 页面滚动状态缓存服务
var ScrollCacheService = {
  scrollPositions: {},
  saveScrollPosition: function (route) {
    this.scrollPositions[route.path] = {
      x: window.pageXOffset,
      y: window.pageYOffset,
    };
  },
  restoreScrollPosition: function (route) {
    var cache = this.scrollPositions[route.path];
    if (cache)
      window.scrollTo(cache.x, cache.y);
  }
};

export default ScrollCacheService;