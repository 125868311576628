import Vue from "vue";

// #region foreachComponents

/**
 * 递归遍历组件以及其所有的子组件，执行指定动作
 * @param {object} component 要遍历的组件
 * @param {(component: object) => void | false} action 要执行的动作
 * @returns
 */
function foreachComponents(component, action) {
  if (typeof action != "function") return false;

  var flag = action(component);
  if (flag === false) return false;

  var children = component.$children;
  if (children.length > 0) {
    for (var i = 0; i < children.length; i++) {
      var child = children[i];
      flag = foreachComponents(child, action);
      if (flag === false) break;
    }
  }
}

/**
 * 递归遍历当前组件以及所有的子组件，执行指定动作
 * @param {(component: object) => void | false} action 要执行的动作
 */
Vue.prototype.$foreachComponents = function (action) {
  foreachComponents(this, action);
};

// #endregion

// #region setViewport

/**
 * 设置移动端的viewport属性
 * @param {Number} destinyWidth 目标宽度
 * @returns
 */
Vue.prototype.$setViewport = function (destinyWidth) {
  var screenWidth = window.screen.width; // 理想视口宽度
  var times = (screenWidth / destinyWidth).round(3); // 换算到目标网页宽度的倍数
  var viewport = document.querySelector("meta[name=viewport]");
  viewport.setAttribute('content', 'width=' + destinyWidth + ',initial-scale=' + times + ',maximum-scale=' + times + ',minimum-scale=' + times + ',user-scalable=0,viewport-fit=cover');
};

// #endregion
