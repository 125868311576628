import Vue from "vue";
import Loading1 from "@/components/Loading1.vue";
import Titlebar from "@/components/Titlebar.vue";
import SorryBlock from "@/components/SorryBlock.vue";
import StepList from '@/components/StepList.vue';
import StepListItem from '@/components/StepListItem.vue';
import GuideOption from '@/components/GuideOption.vue';

Vue.component("loading1", Loading1);
Vue.component("titlebar", Titlebar);
Vue.component("sorry", SorryBlock);
Vue.component("stepList", StepList);
Vue.component("stepListItem", StepListItem);
Vue.component("GuideOption", GuideOption);