<template>
  <div class="loading1" :class="{ 'loading1WithText': !!text }">
    <table class="loading1Table" cellpadding="0" cellspacing="0">
      <tr>
        <td>
          <div>
            <img src="@/assets/img/loading/1.gif" />
          </div>
          <div v-if="!!text" class="loading1Text">
            <span>{{ text }}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    text: { type: String, default: "加载中..." }
  }
}
</script>
<style lang="less" scoped>
  .loading1 {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;

    .loading1Table {
      width: 100%;
      height: 100%;

      td {
        text-align: center;
        vertical-align: middle;
      }
    }

    .loading1Text {
      line-height: 20px;
      font-size: 12px;
      color: #808080;
    }
  }

  .loading1WithText {
    min-width: 54px;
    min-height: 54px;
    width: 54px;
    height: 54px;
  }
</style>
