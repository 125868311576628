var menuData = [
  { id: "home", icon: "home", name: "首页", to: "home" },
  { id: "download", icon: "file_download", name: "下载游戏", to: "download", path: "/download/home" },
  {
    id: "worldList",
    icon: "public",
    name: "游戏周目",
    children: [
      { id: "worldList", name: "周目一览", to: "worldList" },
      { id: "world9", name: "九周目", to: "world9" },
      { id: "world8", name: "八周目", to: "world8" },
      { id: "world7", name: "七周目", to: "world7" },
      { id: "world6", name: "六周目", to: "world6" },
      { id: "world5", name: "五周目", to: "world5" },
      { id: "world4", name: "四周目", to: "world4" },
      { id: "world3", name: "三周目", to: "world3" },
      { id: "world2", name: "二周目", to: "world2" },
      { id: "world1", name: "一周目", to: "world1" }
    ]
  },
  { id: "modList", icon: "favorite", name: "收录Mod库", to: "modList", path: "/modList/1/" },
  { id: "gameVersions", icon: "games", name: "主游戏版本历史", to: "gameVerions" }
];

export default menuData;