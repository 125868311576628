<template>
  <div class="stepList">
    <slot></slot>
  </div>
</template>
<script>
export default {
  methods: {
    updateNums: function () {
      var num = 1;
      window.foreach(this.$children, function (child, index) {
        if (typeof child.num != "undefined")
          child.num = num++;
      });
    }
  },
  mounted: function () {
    this.updateNums();
  }
}
</script>
<style lang="less" scoped>
</style>
