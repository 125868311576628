<template>
  <div class="r-tree">
    <template v-if="data && data.length && data.length > 0">
      <RTreeNode
        v-for="(node, index) in data"
        :key="index"
        :data="node"
        :level="1"
        :showArrow="showArrow"
        :arrowPos="arrowPos"
        :posTdWidth="posTdWidth"
        :iconTdWidth="iconTdWidth"
        :hoveredKey="hoveredKey"
        :selectedKey="selectedKey"
        :expandedKey="expandedKey"
        :onGettingExtraClass="onGettingExtraClass"
        :onMakingIcon="onMakingIcon"
        :onMakingLabel="onMakingLabel"/>
    </template>
  </div>
</template>
<script>
import RTreeNode from "./RTreeNode.vue";

export default {
  name: "RTree",
  components: { RTreeNode },
  data: function () {
    return {
      /** 标识该组件为RTreeNode容器 */
      isTreeNodeContainer: true
    }
  },
  props: {
    /** 节点数据 */
    data: Array,
    /** 是否需要展示组件自带的箭头（默认是） */
    showArrow: { type: Boolean, default: true },
    /** 当showArrow为true时，箭头要显示在节点的左侧"left"还是右侧"right"（默认"left"） */
    arrowPos: { type: String, default: "left" },
    /** 层级占位单元格的宽度（默认为24） */
    posTdWidth: { type: Number, default: 24 },
    /** 图标单元格的宽度（默认为20） */
    iconTdWidth: { type: Number, default: 20 },
    /** 
     * 当值等价于false时， 节点的hovered状态将由组件自行控制
     * 当值为某键名时，组件将从节点数据中获取该键名的值作为hovered状态，此时节点的hovered状态将由父组件控制
     */
    hoveredKey: String,
    /** 
     * 当值等价于false时， 节点的selected状态将由组件自行控制
     * 当值为某键名时，组件将从节点数据中获取该键名的值作为selected状态，此时节点的selected状态将由父组件控制
     */
    selectedKey: String,
    /** 
     * 当值等价于false时， 节点的expanded状态将由组件自行控制
     * 当值为某键名时，组件将从节点数据中获取该键名的值作为expanded状态，此时节点的expanded状态将由父组件控制
     */
    expandedKey: String,
    /** 节点元素生成完全自定义方法 */
    onMakingNode: Function,
    /** 在生成节点时为节点添加额外类名的类名获取方法 */
    onGettingExtraClass: Function,
    /** 图标生成方法 */
    onMakingIcon: Function,
    /** 主文本生成方法 */
    onMakingLabel: Function
  },
  methods: {
    /**
     * 遍历所有的节点VM执行动作
     * @param {(component: object) => void | false} action 要执行的动作
     */
     foreachNodeVm: function (action) {
      this.$foreachComponents(function (vm) {
        if (!vm.isTreeNode) return;
        return action(vm);
      });
    },
    /**
     * 查找满足条件的第一个节点的VM，当未找到时返回null
     * @param {(component: object) => Boolean} condition 判断条件
     */
    getFirstNodeVmBy: function (condition) {
      var target = null;
      this.$foreachComponents(function (vm) {
        if (!vm.isTreeNode) return;
        if (condition(vm)) {
          target = vm;
          return false;
        }
      });
      return target;
    },
    /**
     * 查找并选中满足条件的第一个节点
     * @param {(component: object) => Boolean} condition 判断条件
     */
    selectNode: function (condition) {
      var vm = this.getFirstNodeVmBy(condition);
      if (vm)
        vm.innerSelected = true;
    },
    onNodeHoveredChanged: function (enode, hovered) {
      this.$emit("onNodeHoveredChanged", enode, hovered);
    },
    onNodeSelectedChanged: function (enode, selected) {
      if (selected) {
        if (!this.selectedKey) {
          this.foreachNodeVm(function (vm) {
            if (vm != enode && vm.innerSelected)
              vm.innerSelected = false;
          });
        }
      }
      this.$emit("onNodeSelectedChanged", enode, selected);
    },
    onNodeExpandedChanged: function (enode, expanded) {
      this.$emit("onNodeExpandedChanged", enode, expanded);
    },
    onNodeMouseenter: function (enode) {
      this.$emit("onNodeMouseenter", enode);
    },
    onNodeMouseleave: function (enode) {
      this.$emit("onNodeMouseleave", enode);
    },
    onNodeClick: function (enode) {
      this.$emit("onNodeClick", enode);
    }
  }
};
</script>
<style lang="less" scoped>
  .r-tree {
    text-align: left;
    text-indent: 0;
  }
</style>
