import vue from "vue";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  if (config.url.startsWith("/managerapi/")) {
    if (process.env.NODE_ENV === 'development') {
      config.headers["U"] = "k66880";
      config.headers["P"] = "robertshishangdi,./";
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  if (response.config.url.startsWith("/managerapi/")) {
    if (response.data && !response.data.IsSuccess) {
      var message = '请求失败：' + response.data.Message
      if (response.config.showErrorTip !== false)
        alert(message);
      return Promise.reject(new Error(message));
    }
  }
  return response.data.Body;
}, function (error) {
  return Promise.reject(error);
});

vue.prototype.$axios = axios;
