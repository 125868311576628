// 面板展开收起状态缓存服务
var ExpandedCacheService = {
  cacheKey: "cache-expanded",
  expandedList: [],
  loadCache: function () {
    this.expandedList = [];
    var json = window.localStorage.getItem(this.cacheKey);
    if (json) {
      try {
        this.expandedList = JSON.parse(json);
      }
      catch {}
    }
  },
  updateCache: function () {
    window.localStorage.setItem(this.cacheKey, JSON.stringify(this.expandedList));
  },
  addExpanded: function (id) {
    var index = this.expandedList.indexOf(id);
    if (index >= 0) return;
    this.expandedList.push(id);
    this.updateCache();
  },
  removeExpanded: function (id) {
    var index = this.expandedList.indexOf(id);
    if (index < 0) return;
    this.expandedList.splice(index, 1);
    this.updateCache();
  },
  checkExpanded: function (id) {
    return this.expandedList.indexOf(id) >= 0;
  }
};

ExpandedCacheService.loadCache();

export default ExpandedCacheService;