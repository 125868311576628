<template>
  <div class="stepItem">
    <div class="stepItemNum">{{ num }}</div>
    <div class="stepItemContent">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      num: 0
    }
  }
}
</script>
<style lang="less" scoped>
.stepItem {
  position: relative;
  width: 100%;
  text-align: left;

  .stepItemContent {
    line-height: 28px;
    padding: 10px 0 10px 40px;
    font-size: 16px;

    p {
      line-height: 22px;
      padding: 5px 0;
    }
  }

  .stepItemNum {
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 12px;
    line-height: 20px;
    padding: 2px 8px;
    background-color: #6eb3c3;
    border-radius: 20px;
    color: white;
  }
}
</style>
