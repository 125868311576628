import Vue from 'vue';

const _FORMAT_DATE = "{yyyy}.{M}.{d}";
const _FORMAT_DATETIME = "{yyyy}-{M}-{d} {HH}:{mm}:{ss}";

// 格式化日期时间
function _formatDateTime(str, format) {
    if (!str || str == "") return "";
    str = str.replaceAll("-", "/");
    str = str.replaceAll("T", " ");
    var dotindex = str.indexOf(".");
    if (dotindex >= 1) str = str.substring(0, dotindex);
    var obj = new Date(str);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var year = obj.getFullYear();
    var month = obj.getMonth() + 1;
    var date = obj.getDate();
    var hour = obj.getHours();
    var minute = obj.getMinutes();
    var second = obj.getSeconds();

    // yyyy
    var yyyy = year;

    // yy
    var yy = parseInt(year - parseInt(year / 100) * 100);
    if (yy < 10) yy = "0" + yy;

    // MMM
    var MMM = (month >= 1 && month <= 12) ? months[month - 1] : "error";

    // MM
    var MM = month;
    if (MM < 10) MM = "0" + MM;

    // M
    var M = month;

    // dd
    var dd = date;
    if (dd < 10) dd = "0" + dd;

    // d
    var d = date;

    // HH
    var HH = hour;
    if (HH < 10) HH = "0" + HH;

    // H
    var H = hour;

    // mm
    var mm = minute;
    if (mm < 10) mm = "0" + mm;

    // m
    var m = minute;

    // ss
    var ss = second;
    if (ss < 10) ss = "0" + ss;

    // s
    var s = second;

    format = format.replace(/{yyyy}/g, yyyy);
    format = format.replace(/{MMM}/g, MMM);
    format = format.replace(/{MM}/g, MM);
    format = format.replace(/{M}/g, M);
    format = format.replace(/{dd}/g, dd);
    format = format.replace(/{d}/g, d);
    format = format.replace(/{HH}/g, HH);
    format = format.replace(/{H}/g, H);
    format = format.replace(/{mm}/g, mm);
    format = format.replace(/{m}/g, m);
    format = format.replace(/{ss}/g, ss);
    format = format.replace(/{s}/g, s);

    return format;
}
// 格式化日期
var formatDate = function (value) {
  if (!value) return "-";
  return _formatDateTime(value, _FORMAT_DATE);
}
// 格式化日期时间
var formatDateTime = function (value) {
  if (!value) return "-";
  return _formatDateTime(value, _FORMAT_DATETIME);
}

Vue.filter("formatDate", formatDate);
Vue.filter("formatDateTime", formatDateTime);