<template>
  <table class="guideOption" :class="classes" cellpadding="0" cellspacing="0" @click="onClick()">
    <tr class="guideOptionTr">
      <td class="guideOptionIconCon">
        <img class="guideOptionIcon" src="@/assets/img/goto.png"/>
      </td>
      <td class="guideOptionText">
        <slot></slot>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    size: { type: String, default: "normal" }
  },
  computed: {
    classes: function () {
      var res = {};
      if (this.size == "small")
        res["guideOption_small"] = true;
      else if (this.size == "large")
        res["guideOption_large"] = true;
      return res;
    }
  },
  methods: {
    onClick: function () {
      this.$emit("click");
    }
  }
}
</script>
<style lang="less" scoped>
  .guideOption {
    table-layout: fixed;
    width: 100%;
    background-color: #e8f4f5;
    border-radius: 8px;
    margin-top: 8px;
    cursor: pointer;

    .guideOptionIconCon {
      width: 48px;
      text-align: center;
      vertical-align: top;

      .guideOptionIcon {
        width: 24px;
        margin-left: 12px;
        margin-top: 12px;
        float: left;
      }
    }

    .guideOptionText {
      width: auto;
      line-height: 28px;
      padding: 10px 12px 10px 0;
      font-size: 18px;
      // font-family: "Consolas", "微软雅黑";
      color: black;
    }
  }

  .guideOption_small {
    .guideOptionText {
      font-size: 16px;
    }
  }

  .guideOption_large {
    .guideOptionText {
      font-size: 22px;
    }
  }

  .guideOption:hover {
    background-color: #dbf5fc;
  }
</style>
