import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    dicModGroups: [
      { id: 1, name: "世界" },
      { id: 2, name: "冒险" },
      { id: 3, name: "科技" },
      { id: 4, name: "魔法" },
      { id: 5, name: "农业" },
      { id: 6, name: "装饰" },
      { id: 7, name: "实用" },
      { id: 8, name: "辅助" },
      { id: 9, name: "前置" }
    ],
    isSidebarExpanded: false
  },
  mutations: {
    expandSidebar (state) {
      state.isSidebarExpanded = true;
    },
    collapseSidebar (state) {
      state.isSidebarExpanded = false;
    }
  }
});

export default store;