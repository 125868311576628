<template>
  <div class="miniLogo" @click="onExpand()">
    <img class="logoImg" src="@/assets/img/logo/logo2.png"/>
    <i class="expandBtn icon-view_headline"></i>
  </div>
</template>
<script>
export default {
  methods: {
    onExpand: function () {
      this.$emit("onExpand");
    }
  }
}
</script>
<style lang="less" scoped>
  .miniLogo {
    position: relative;
    width: 70px;
    height: 40px;

    .logoImg {
      position: absolute;
      left: 8px;
      top: 6px;
      height: 30px;
    }

    .expandBtn {
      position: absolute;
      right: 8px;
      top: 10px;
      font-size: 20px;
      color: #808080;
    }
  }
</style>
