<template>
  <div class="sorryBlock">
    <div><img class="sorryimg" :src="imgSrc"/></div>
    <div class="sorrytext">{{ text }}</div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      imgNum: null
    };
  },
  props: {
    text: { type: String, default: "" }
  },
  computed: {
    imgSrc: function () {
      if (!this.imgNum) return "";
      return "https://static.wjbin.com/website/sorryimg/" + this.imgNum + ".jpg";
    }
  },
  mounted: function () {
    this.imgNum =  parseInt(Math.random() * 3) + 1;
  }
}
</script>
<style lang="less" scoped>
  .sorryBlock {
    padding: 50px 50px;
    text-align: center;

    .sorryimg {
      max-width: 150px;
    }

    .sorrytext {
      font-size: 16px;
      color: #808080;
    }
  }
</style>
