<template>
  <div class="titlebarCon">
    <div class="titlebar" :class="mainClass">
      <MiniLogo class="miniLogo" @onExpand="onExpandSidebar()"/>
      <div class="titleContent">
        <span v-if="!!text" class="titleText">{{ text }}</span>
        <slot></slot>
      </div>
      <div class="btnBack" v-if="showBack" @click="onBack()">返回</div>
    </div>
    <div class="titlebarPos" v-if="showPos"></div>
  </div>
</template>
<script>
import MiniLogo from "@/components/MiniLogo.vue";
export default {
  components: { MiniLogo },
  props: {
    text: String,
    fixed: { type: Boolean, default: true },
    dark: { type: Boolean, default: false },
    showPos: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false }
  },
  computed: {
    mainClass: function () {
      var classes = {};
      if (this.fixed)
        classes["titlebar_fixed"] = true;
      if (this.dark)
        classes["titlebar_dark"] = true;
      return classes;
    }
  },
  methods: {
    onExpandSidebar: function () {
      this.$store.commit("expandSidebar");
    },
    onBack: function () {
      this.$emit("onBack");
    }
  }
}
</script>
<style lang="less" scoped>
  .titlebar {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #f8f8f8;
    box-shadow: 0px 0px 5px #d0d0d0;

    .miniLogo {
      position: absolute;
      left: 0;
      top: 0;
      transition: left 0.5s;
    }
    // .miniLogo_collapsed {
    //   left: -80px;
    // }

    .titleContent {
      padding: 0 80px;
      text-align: center;

      .titleText {
        line-height: 40px;
        font-size: 16px;
      }
    }

    .btnBack {
      position: absolute;
      top: 0;
      right: 0;
      line-height: 40px;
      padding: 0 10px;
    }
  }

  .titlebar_fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
  }
  
  .titlebar_dark {
    background-color: rgba(240, 240, 240, 0.7);
    box-shadow: 0px 0px 5px #808080;
  }

  .titlebarPos {
    width: 100%;
    height: 40px;
  }
</style>
  