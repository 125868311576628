<script>
export default {
  name: "RTreeNode",
  render: function (h) {
    // 自定义节点元素生成
    if (this.onMakingNode)
      return this.onMakingNode(h, this);

    // 生成子节点
    var childrenCon = null;
    var childNodes = [];
    if (this.data) {
      window.foreach(this.data.children, cNodeData => {
        var cnode = h("RTreeNode", {
          props: {
            data: cNodeData,
            level: this.level + 1,
            showArrow: this.showArrow,
            arrowPos: this.arrowPos,
            posTdWidth: this.posTdWidth,
            iconTdWidth: this.iconTdWidth,
            hoveredKey: this.hoveredKey,
            selectedKey: this.selectedKey,
            expandedKey: this.expandedKey,
            onMakingNode: this.onMakingNode,
            onGettingExtraClass: this.onGettingExtraClass,
            onMakingIcon: this.onMakingIcon,
            onMakingLabel: this.onMakingLabel
          }
        });
        childNodes.push(cnode);
      });
    }
    if (childNodes.length > 0) {
      childrenCon = h("div", {
          class: {
            "r-tree-node-childrencon": true,
            "r-tree-node-childrencon-expanded": this.isExpanded
          }
        }, childNodes);
    }

    var tds = [];
    var arrow, tdarrow;

    // 生成层级占位
    if (this.level > 1) {
      for (var i = 0; i < this.level - 1; i++) {
        var tdpos = h("td", { class: "r-tree-node-tdpos", style: { "width": this.posTdWidth + "px" } });
        tds.push(tdpos);
      }
    }

    // 生成左侧箭头
    if (this.showArrow && this.arrowPos == "left") {
      arrow = h("div", {
        class: {
          "r-tree-node-arrow": true,
          "r-tree-node-arrow-branch": this.hasChildren,
          "r-tree-node-arrow-leaf": !this.hasChildren
        }
      });
      tdarrow = h("td", { class: "r-tree-node-tdarrow r-tree-node-tdarrow-left" }, [arrow]);
      tds.push(tdarrow);
    }

    // 生成图标
    if (this.onMakingIcon) {
      var icon = this.onMakingIcon(h, this);
      if (icon) {
        if (typeof icon == "string")
          icon = h("i", { class: "r-tree-node-icon " + icon });
        var tdicon = h("td", { class: "r-tree-node-tdicon", style: { "width": this.iconTdWidth + "px" } }, [icon]);
        tds.push(tdicon);
      }
    }

    // 生成主文本
    if (this.onMakingLabel) {
      var label = this.onMakingLabel(h, this);
      if (typeof label == "string")
        label = h("span", { class: "r-tree-node-label" }, label);
      var tdlabel = h("td", { class: "r-tree-node-tdlabel" }, [label]);
      tds.push(tdlabel);
    }

    // 生成右侧箭头
    if (this.showArrow && this.arrowPos == "right" && this.hasChildren) {
      arrow = h("div", {
        class: {
          "r-tree-node-arrow": true,
          "r-tree-node-arrow-branch": this.hasChildren,
          "r-tree-node-arrow-leaf": !this.hasChildren
        }
      });
      tdarrow = h("td", { class: "r-tree-node-tdarrow r-tree-node-tdarrow-right" }, [arrow]);
      tds.push(tdarrow);
    }

    // 生成节点主table
    var table = h(
      "table",
      {
        class: "r-tree-node-table",
        attrs: { cellpadding: "0", cellspacing: "0" },
        on: {
          mouseenter: this.onMouseenter,
          mouseleave: this.onMouseleave,
          click: this.onClick
        }
      },
      [
        h("tr", { class: "r-tree-node-tr" }, tds)
      ]
    );

    // 生成最终节点
    var node = h(
      "div",
      {
        class: this.nodeClassName
      },
      [table, childrenCon]
    );

    return node;
  },
  data: function () {
    return {
      /** 标识该组件为RTreeNode */
      isTreeNode: true,
      /** 当未设置hoveredKey时，内部管控的hovered状态 */
      innerHovered: false,
      /** 当未设置selectedKey时，内部管控的selected状态 */
      innerSelected: false,
      /** 当未设置expandedKey时，内部管控的expanded状态 */
      innerExpanded: false
    };
  },
  props: {
    /** 节点绑定的数据 */
    data: Object,
    /** 当前节点的层级，从1开始 */
    level: { type: Number, default: 1 },
    /** 是否需要展示组件自带的箭头（默认是） */
    showArrow: { type: Boolean, default: true },
    /** 当showArrow为true时，箭头要显示在节点的左侧"left"还是右侧"right"（默认"left"） */
    arrowPos: { type: String, default: "left" },
    /** 层级占位单元格的宽度（默认为24） */
    posTdWidth: { type: Number, default: 24 },
    /** 图标单元格的宽度（默认为20） */
    iconTdWidth: { type: Number, default: 20 },
    /** 
     * 当值等价于false时， 节点的hovered状态将由组件自行控制
     * 当值为某键名时，组件将从节点数据中获取该键名的值作为hovered状态，此时节点的hovered状态将由父组件控制
     */
    hoveredKey: String,
    /** 
     * 当值等价于false时， 节点的selected状态将由组件自行控制
     * 当值为某键名时，组件将从节点数据中获取该键名的值作为selected状态，此时节点的selected状态将由父组件控制
     */
    selectedKey: String,
    /** 
     * 当值等价于false时， 节点的expanded状态将由组件自行控制
     * 当值为某键名时，组件将从节点数据中获取该键名的值作为expanded状态，此时节点的expanded状态将由父组件控制
     */
    expandedKey: String,
    /** 节点元素生成完全自定义方法 */
    onMakingNode: Function,
    /** 在生成节点时为节点添加额外类名的类名获取方法 */
    onGettingExtraClass: Function,
    /** 图标生成方法 */
    onMakingIcon: Function,
    /** 主文本生成方法 */
    onMakingLabel: Function
  },
  computed: {
    nodeClassName: function () {
      var classes = ["r-tree-node"];
      if (this.isHovered) classes.push("r-tree-node-hovered");
      if (this.isSelected) classes.push("r-tree-node-selected");
      if (this.isExpanded) classes.push("r-tree-node-expanded");
      if (this.onGettingExtraClass) {
        var extra = this.onGettingExtraClass(this);
        if (extra) classes.push(extra);
      }
      return classes.join(' ');
    },
    hasChildren: function () {
      if (!this.data || !this.data.children) return false;
      if (typeof this.data.children.length != "number") return false;
      return this.data.children.length > 0;
    },
    isHovered: function () {
      if (!this.hoveredKey) return this.innerHovered;
      return !!this.data[this.hoveredKey];
    },
    isSelected: function () {
      if (!this.selectedKey) return this.innerSelected;
      return !!this.data[this.selectedKey];
    },
    isExpanded: function () {
      if (!this.expandedKey) return this.innerExpanded;
      return !!this.data[this.expandedKey];
    }
  },
  watch: {
    "isHovered": function (value) {
      this.triggerContainerEvent("onNodeHoveredChanged", [this, value]);
    },
    "isSelected": function (value) {
      this.triggerContainerEvent("onNodeSelectedChanged", [this, value]);
    },
    "isExpanded": function (value) {
      this.triggerContainerEvent("onNodeExpandedChanged", [this, value]);
    }
  },
  methods: {
    /**
     * 寻找节点容器
     * 向上遍历VM，直到找到第一个isTreeNodeContainer=true的组件，若找不到则返回null
     */
    getContainer: function () {
      var parent = this.$parent;
      while (parent) {
        if (parent.isTreeNodeContainer) return parent;
        parent = parent.$parent;
      }
      return null;
    },
    /** 主动触发节点容器的事件处理函数 */
    triggerContainerEvent: function (funcName, args) {
      var container = this.getContainer();
      if (!container) return;
      if (typeof container[funcName] != "function") return;
      container[funcName].apply(this, args);
    },
    onMouseenter: function () {
      if (!this.hoveredKey)
        this.innerHovered = true;
      this.triggerContainerEvent("onNodeMouseenter", [this]);
    },
    onMouseleave: function () {
      if (!this.hoveredKey)
        this.innerHovered = false;
      this.triggerContainerEvent("onNodeMouseleave", [this]);
    },
    onClick: function () {
      if (!this.selectedKey && !this.hasChildren)
        this.innerSelected = true;
      if (!this.expandedKey && this.hasChildren)
        this.innerExpanded = !this.innerExpanded;
      this.triggerContainerEvent("onNodeClick", [this]);
    }
  }
}
</script>
<style lang="less" scoped>
  .r-tree-node {
    width: 100%;
    min-height: 10px;

    >.r-tree-node-table {
      table-layout: fixed;
      width: 100%;
      border-bottom: 1px solid #fafafa;
      cursor: default;
      -webkit-transition: background linear 0.2s;
      -moz-transition: background linear 0.2s;
      -ms-transition: background linear 0.2s;
      -o-transition: background linear 0.2s;
      transition: background linear 0.2s;

      // .r-tree-node-tdpos {
      // }

      .r-tree-node-tdarrow {
        line-height: 28px;
        padding-right: 3px;
        vertical-align: top;

        .r-tree-node-arrow {
          display: inline-block;
          width: 10px;
          height: 10px;
          margin: 9px auto auto auto;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEbSURBVDhPndKxbsIwEIDhOxM1AwswdexCJXgKWHkJylZg6dax6tS+AGTkETp3grFjlw5VOjB0YCNSJ4Tg6hMnK20cYvtfbMfSJycxwr/qs4/LGC/uCdSG6PCaTa/fZcspJaMpVvGTHu4Qjs8K4aWRpL3TjlsFEIDWMtHhlSJa+KCFV+aa83SpN3IIrY+Io2zcXsmD0qwgF4qWglwIehbkfNFKkPNBnUDOjh762biTuxXWa2NvO2n3CSB3In2lIBrKwuQMcoj45zSMysTkDLaSrwUQ3chSp18Z9o+yMDl9Qytm+X5cJeiDcWdBX4wrBUMwzgqGYlzhL7eS9CEU4yqujR/G1WQ0qcHtZ4S1SE/fdrQf/Uy636cdlwB+AUYppOEiRSWoAAAAAElFTkSuQmCC");
          background-size: 10px 10px;
          transition: transform 0.4s;
        }

        .r-tree-node-arrow-branch {
          background-position: -10px 0;
        }

        .r-tree-node-arrow-leaf {
          background-position: -20px 0;
        }
      }

      .r-tree-node-tdarrow-left {
        width: 20px;
        text-align: right;
      }

      .r-tree-node-tdarrow-right {
        width: 24px;
        text-align: center;
      }

      .r-tree-node-tdicon {
        line-height: 28px;
        padding-right: 3px;
        text-align: right;
        vertical-align: top;
      }

      .r-tree-node-tdlabel {
        width: auto;
        line-height: 22px;
        padding: 3px 5px;
        vertical-align: top;
      }
    }

    .r-tree-node-childrencon {
      display: none;
    }

    .r-tree-node-childrencon-expanded {
      display: block;
    }
  }

  .r-tree-node-hovered {
    >.r-tree-node-table {
      background-color: #d7eeff;
    }
  }

  .r-tree-node-selected {
    >.r-tree-node-table {
      background-color: #bde3ff;
    }
  }

  .r-tree-node-expanded {
    >.r-tree-node-table {
      .r-tree-node-tdarrow {
        .r-tree-node-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }
</style>
