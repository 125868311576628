import Vue from "vue";
import router from "@/base/router.js";
import store from "@/base/store.js";
import "@/base/directive.js";
import "@/base/filters.js";
import "@/base/axios.js";
import "@/base/img-vuer.js";
import "@/base/vue-lazyload.js";
import App from "./App.vue";
import "@/assets/js/VueExtensions/VueExtensions.js";
import "@/assets/js/RUI/importAll.js";
import "@/assets/js/RCommon/RCommon-1.6.0.js";
import "@/assets/css/icomoon/style.css";
import "@/assets/css/common/common.less";
import "@/assets/css/common/common.mc.less";
import "@/base/globalComponents.js";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');